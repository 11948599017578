<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['รายการแทง', 'คำนวณ']" />
    <div class="card mb-3 border">
      <div class="card-body">
        <div class="d-flex mb-3">
          <div class="mr-2">ยอดรวม</div>
          <div>{{ util.formatMoneyNormal(calculateInfo?.sumBuyAmount) }}</div>
        </div>
        <b-form class="mb-3" inline>
          <b-form-input
            class="mr-2 w-number"
            v-model="form.number1"
            oninput="this.value=this.value.slice(0,this.maxLength)"
            maxlength="1"
            type="number"
            placeholder="0"
          ></b-form-input>
          <b-form-input
            class="mr-2 w-number"
            v-model="form.number2"
            oninput="this.value=this.value.slice(0,this.maxLength)"
            maxlength="1"
            type="number"
            placeholder="0"
          ></b-form-input>
          <b-form-input
            class="mr-2 w-number"
            v-model="form.number3"
            oninput="this.value=this.value.slice(0,this.maxLength)"
            maxlength="1"
            type="number"
            placeholder="0"
          ></b-form-input>
          <b-form-input
            class="mr-2 w-number"
            v-model="form.number4"
            oninput="this.value=this.value.slice(0,this.maxLength)"
            maxlength="1"
            type="number"
            placeholder="0"
          ></b-form-input>
          <b-form-input
            class="mr-2 w-number"
            v-model="form.number5"
            oninput="this.value=this.value.slice(0,this.maxLength)"
            maxlength="1"
            type="number"
            placeholder="0"
          ></b-form-input>
          <b-form-input
            class="mr-2 w-number"
            v-model="form.number6"
            oninput="this.value=this.value.slice(0,this.maxLength)"
            maxlength="1"
            type="number"
            placeholder="0"
          ></b-form-input>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="form.limit"
            :options="[
              { text: '100', value: 100 },
              { text: '500', value: 500 },
              { text: '1000', value: 1000 },
              { text: 'ทั้งหมด', value: 1000000 },
            ]"
            :value="100"
          ></b-form-select>

          <b-button variant="primary" @click="getInfo">ค้นหา</b-button>
        </b-form>
        <div class="d-flex">
          <div class="form-group form-check mr-4 mb-0">
            <input
              v-model="form.salakFirst3"
              type="checkbox"
              class="form-check-input"
              id="SALAK_FIRST_3"
            />
            <label class="form-check-label" for="SALAK_FIRST_3"
              >รางวัลเลขหน้า 3 ตัว</label
            >
          </div>
          <div class="form-group form-check mr-4 mb-0">
            <input
              v-model="form.salakLast3"
              type="checkbox"
              class="form-check-input"
              id="SALAK_LAST_3"
            />
            <label class="form-check-label" for="SALAK_LAST_3"
              >รางวัลเลขท้าย 3 ตัว</label
            >
          </div>
          <div class="form-group form-check mr-4 mb-0">
            <input
              v-model="form.salakLast2"
              type="checkbox"
              class="form-check-input"
              id="SALAK_LAST_2"
            />
            <label class="form-check-label" for="SALAK_LAST_2"
              >รางวัลเลขท้าย 2 ตัว</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <select v-model="sort" class="form form-control w-auto mb-3">
        <option value="BY_BUY_AMOUNT">ยอดซื้อมากไปน้อย</option>
        <option value="BY_NUMBER_ASC">หมายเลข น้อยไปมาก</option>
        <option value="BY_NUMBER_DESC">หมายเลข มากไปน้อย</option>
      </select>
    </div>
    <div class="table-responsive" v-if="calculateInfo">
      <table class="table table-dark table-striped text-center">
        <thead>
          <tr>
            <th>เลข</th>
            <th>ซื้อ</th>
            <th v-for="(item, index) in huayCategoryPays" :key="index">
              <div class="d-flex flex-column">
                <span>{{ item.name }}</span>
                <span>{{ util.formatMoneyNormal(item.payrate) }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in calculateInfo.list" :key="index">
            <td class="align-middle">{{ item.number }}</td>
            <td class="align-middle positive">
              {{ util.formatMoneyNormal(item.buyAmount) }}
            </td>
            <td
              v-if="isShowAllPayAmount"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalak1St)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalak1St) }}
            </td>
            <td
              v-if="isShowAllPayAmount || form.salakFirst3"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalakFirst3)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalakFirst3) }}
            </td>
            <td
              v-if="isShowAllPayAmount || form.salakLast3"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalakLast3)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalakLast3) }}
            </td>
            <td
              v-if="isShowAllPayAmount || form.salakLast2"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalakLast2)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalakLast2) }}
            </td>
            <td
              v-if="isShowAllPayAmount"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalakNear1St)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalakNear1St) }}
            </td>
            <td
              v-if="isShowAllPayAmount"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalak2Nd)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalak2Nd) }}
            </td>
            <td
              v-if="isShowAllPayAmount"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalak3Rd)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalak3Rd) }}
            </td>
            <td
              v-if="isShowAllPayAmount"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalak4Th)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalak4Th) }}
            </td>
            <td
              v-if="isShowAllPayAmount"
              class="align-middle"
              :class="getClassPayAmount(item.payAmountSalak5Th)"
            >
              {{ util.formatMoneyNormal(item.payAmountSalak5Th) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort: "BY_BUY_AMOUNT",
      form: {
        number1: null,
        number2: null,
        number3: null,
        number4: null,
        number5: null,
        number6: null,
        limit: 100,
      },
      calculateInfo: null,
    }
  },
  watch: {
    sort(val) {
      this.sortCalculateInfo(val)
    },
  },
  computed: {
    isShowAllPayAmount() {
      return (
        !this.form.salakFirst3 && !this.form.salakLast3 && !this.form.salakLast2
      )
    },
    huayCategoryPays() {
      return this.calculateInfo?.huayCategoryPays.filter((item) => {
        if (this.isShowAllPayAmount) {
          return item
        } else if (this.form.salakFirst3 && item.code === "SALAK_FIRST_3") {
          return item
        } else if (this.form.salakLast3 && item.code === "SALAK_LAST_3") {
          return item
        } else if (this.form.salakLast2 && item.code === "SALAK_LAST_2") {
          return item
        }
      })
    },
  },
  methods: {
    getClassPayAmount(amount) {
      return amount < 0 ? "negative" : "positive"
    },
    sortCalculateInfo(val) {
      this.calculateInfo?.list?.sort(function (a, b) {
        if (val === "BY_NUMBER_ASC") {
          return a.number > b.number ? 1 : -1
        } else if (val === "BY_NUMBER_DESC") {
          return a.number < b.number ? 1 : -1
        }

        return a.buyAmount < b.buyAmount ? 1 : -1
      })
    },
    async getInfo() {
      this.form.huayRoundId = this.$store.state.auth.roundId

      this.swal.processing()
      try {
        const res = await this.axios({
          method: "post",
          url: "/huay/calculate/info",
          data: this.form,
        })
        const data = res.data.data

        this.calculateInfo = data

        this.sortCalculateInfo(this.sort)

        this.swal.close()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
  async mounted() {},
}
</script>

<style lang="scss" scoped>
.w-number {
  width: 40px;
}
</style>
